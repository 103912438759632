var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "loginForm" }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _vm.haveStarsData
          ? _c("TextBox", {
              staticClass: "textBox",
              attrs: {
                label: "StarsId",
                type: "textbox",
                name: "starsId",
                disabled: true,
              },
              model: {
                value: _vm.userObject.starsId,
                callback: function ($$v) {
                  _vm.$set(_vm.userObject, "starsId", $$v)
                },
                expression: "userObject.starsId",
              },
            })
          : _vm._e(),
        _c("TextBox", {
          staticClass: "textBox",
          attrs: {
            label: "First Name",
            name: "nameFirst",
            disabled: _vm.haveStarsData,
          },
          model: {
            value: _vm.userObject.nameFirst,
            callback: function ($$v) {
              _vm.$set(_vm.userObject, "nameFirst", $$v)
            },
            expression: "userObject.nameFirst",
          },
        }),
        _c("TextBox", {
          staticClass: "textBox",
          attrs: {
            label: "Last Name",
            name: "nameLast",
            disabled: _vm.haveStarsData,
          },
          model: {
            value: _vm.userObject.nameLast,
            callback: function ($$v) {
              _vm.$set(_vm.userObject, "nameLast", $$v)
            },
            expression: "userObject.nameLast",
          },
        }),
        _c("TextBox", {
          staticClass: "textBox",
          attrs: {
            label: "Preferred Name",
            name: "namePreferred",
            required: true,
          },
          model: {
            value: _vm.userObject.namePreferred,
            callback: function ($$v) {
              _vm.$set(_vm.userObject, "namePreferred", $$v)
            },
            expression: "userObject.namePreferred",
          },
        }),
        !_vm.haveStarsData
          ? _c("TextBox", {
              staticClass: "textBox",
              attrs: { label: "Position", name: "position", required: true },
              model: {
                value: _vm.userObject.nonStarsPosition,
                callback: function ($$v) {
                  _vm.$set(_vm.userObject, "nonStarsPosition", $$v)
                },
                expression: "userObject.nonStarsPosition",
              },
            })
          : _vm._e(),
        !_vm.haveStarsData
          ? _c("TextBox", {
              staticClass: "textBox",
              attrs: { label: "Location", name: "location", required: true },
              model: {
                value: _vm.userObject.location,
                callback: function ($$v) {
                  _vm.$set(_vm.userObject, "location", $$v)
                },
                expression: "userObject.location",
              },
            })
          : _vm._e(),
        _c("TextBox", {
          staticClass: "textBox",
          attrs: {
            label: "Email",
            type: "email",
            name: "email",
            required: true,
            validationFunction: _vm.isValidEmail,
          },
          model: {
            value: _vm.userObject.email,
            callback: function ($$v) {
              _vm.$set(_vm.userObject, "email", $$v)
            },
            expression: "userObject.email",
          },
        }),
        _c("TextBox", {
          staticClass: "textBox",
          attrs: { label: "Phone", type: "tel", name: "phone" },
          model: {
            value: _vm.userObject.phone,
            callback: function ($$v) {
              _vm.$set(_vm.userObject, "phone", $$v)
            },
            expression: "userObject.phone",
          },
        }),
        _c("ButtonSubmit", {
          staticClass: "buttonOffset",
          attrs: { copy: "Send Now" },
          on: { callback: _vm.submit },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }