var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "loginReal" },
    [
      _c("LoadingOverlay", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showLoader,
            expression: "showLoader",
          },
        ],
      }),
      _c("LoginSTARS", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showFormSTARS,
            expression: "showFormSTARS",
          },
        ],
        attrs: { wslx: _vm.wslxObject },
        on: { gotStars: _vm.gotStars, noStars: _vm.noStars },
      }),
      _vm.showForm
        ? _c("LoginForm", {
            attrs: { stars: _vm.starsObject, wslx: _vm.wslxObject },
            on: { savedUser: _vm.savedUser },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }