var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "starsID" }, [
    _c("p", [
      _vm._v(
        "\n    Welcome to the Effortless Guide!\n    To get started, please enter your STARS ID and click the 'Lookup' button.\n  "
      ),
    ]),
    _c(
      "form",
      {
        staticClass: "formSection",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "title" }, [_vm._v("STARS ID LOOKUP")]),
        _c("TextBox", {
          staticClass: "textBox",
          attrs: { label: "STARS ID", name: "starsID" },
          model: {
            value: _vm.starsObject.starsID,
            callback: function ($$v) {
              _vm.$set(_vm.starsObject, "starsID", $$v)
            },
            expression: "starsObject.starsID",
          },
        }),
        _c("div", { staticClass: "notFound" }, [_vm._v(_vm._s(this.message))]),
        _c("ButtonSubmit", {
          staticClass: "buttonOffset",
          attrs: { copy: "Lookup" },
          on: { callback: _vm.submit },
        }),
      ],
      1
    ),
    _vm.doNotHaveStarsId
      ? _c("button", { staticClass: "noStarBtn", on: { click: _vm.noStars } }, [
          _vm._v("\n    Don't Have STARS ID, Click Here\n    "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }