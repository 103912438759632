var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "loginFake" }, [
    _c("div", { staticClass: "dropdown" }, [
      _c("label", [_vm._v("Select User:")]),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.value,
              expression: "value",
            },
          ],
          attrs: { required: "" },
          on: {
            change: [
              function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.value = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
              _vm.selectedUser,
            ],
          },
        },
        [
          _c("option", { attrs: { value: "" } }, [_vm._v("Select User")]),
          _vm._l(_vm.fakeUsers, function (option, index) {
            return _c("option", { key: index, domProps: { value: option } }, [
              _vm._v(_vm._s(option.value)),
            ])
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }